import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Seo from '../components/Seo/Seo';
import Image from '../components/Image/Image';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ShareBar from '../components/ShareBar/ShareBar';
import '../scss/main.scss';

const TeamMemberTemplate = ({data}) => {
    const { name, role, bio, image } = data?.contentfulCoach;
    const pageTitle = data?.contentfulPages?.title;
    const [loaded, setLoaded] = useState('');
    
    return (
      <>
      <Seo data={data?.contentfulCoach}/>
      <Header />
      <main className={`post ${loaded}`}>
       {pageTitle && <h1>{pageTitle}</h1>}
        <div className='content'>
          {image && <Image {...image} onLoad={() => setLoaded('loaded')}/>}
          {name && <h2>{name}</h2>}
          {role && <h3>{role}</h3>}
          {data?.contentfulCoach && <ShareBar {...data?.contentfulCoach}/>}
          {bio && <p>
          {renderRichText(bio)} 
          </p>}
        </div>{/* content */}      
      </main>
      <Footer/>
      </>
    )
}

export default TeamMemberTemplate

export const pageQuery = graphql`
  query CoachBySlug($slug: String!) {
    contentfulCoach(slug: { eq: $slug }) {
      bio {
        raw
      }
      image {
        file {
          url
        }
      }
      slug
      name
      role
      metaTitle
      metaDesc
      metaImage {
        file {
          url
        }
      }
    }
    contentfulPages(slug: { eq: "coaches" }) {
      slug
      title
      metaTitle
      metaDesc
      metaImage {
        file {
          url
        }
      }
      image {
        file {
          url
        }
      }
      headline
      description {
        raw
      }
    }
  }
`
